import axios from 'axios';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import {getLocationQuery, slugify, getCookie} from "../utility";
import {matchPath} from "react-router";

const defaultCatalogState = {
    pageSize: 15,
    page: 1,
    totalResults: 0,
    products: [],
    breadCrumbs: [],
    filters: [],
    routeFacets: [],
    facets: [],
    showCompatibles: null,
    categoryPath: [],
    isCategoryLandingPage: false,
    productCrumbs: [],
    showAllFilter: false,
    facilityId: -1,
    showNoResults: false,
    showRequestQuote: false,
    campaign: null,
    viewAll: false,
    filterSearchFocused: false,
    filterSearchText: '',
    pageTitle: 'Catalog',
    pageDescription: 'Catalog : PartsSource - Healthcare Products and Solutions',
    filtersMenuVisibleSections: [],
    isMobileMenuVisible: false,
    categoryRoots: [],
    pathname: '',
    search: '',
};

const facetExpansionCutoff = 5; // Show More link when a facet has more than this number of selections
const facetSearchCutoff = 40; // Show edit rather than checks when a facet has more than this number of selections
const showMorePageSize = 10;

export const actionCreators = {
    performSearch: (request, id_ins) => performSearch(request, id_ins),
    saveCatalogState: (changes) => saveCatalogState(changes),
    resetCatalog: () => resetCatalog(),
};

export const ActionShape = _.mapValues(actionCreators, () => PropTypes.func);

export function performSearch(request, id_ins) {
    return (dispatch, getState) => {
        const {
            network: {isLoggedIn},
            user: {settings, facility, facilities},
            companies: {categories},
            catalog: {pageTitle: oldTitle, pageDescription: oldDescription}
        } = getState();

        const {
            location,
            history,
            params: {referer},
            match: {params: {oemName, modelName, cat1, cat2, cat3, cat4, cat5}},
        } = request;
        
        let urlParams = getLocationQuery(location);

        if (urlParams.endpoint || urlParams.token) {
            delete urlParams.endpoint;
            delete urlParams.token;
        }

        const pageSize = (urlParams && urlParams._pageSize) ? parseInt(urlParams._pageSize) : 15;
        const page = (urlParams && urlParams._page) ? parseInt(urlParams._page) : 1;
        if (location.pathname === '/catalog' && urlParams && !urlParams['q']) {
            const [key, value2q] = Object.entries(urlParams)[0];
            urlParams = {...urlParams, q:value2q};
        }    
        // Convert only relevant parameters to facets
        const relevantParams = {...urlParams};
        ['ctm', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].forEach(param => {
            if (relevantParams[param]) delete relevantParams[param];
        });
        const facets = convertToFacets(relevantParams);
        const urlHasFacets = Object.keys(facets).length > 0;
        if (!id_ins || (typeof document == undefined)) {
            id_ins = getCookie('id_ins');
        }
        let skipPricing = settings.hidePricing || (settings.hasFacilityLevelPricing && !(facility.facilityId && facility.facilityId > 0));

        let pageTitle = oldTitle;
        let pageDescription = oldDescription;
        let url = location.pathname + location.search;
        if (cat1 && categories.length > 0) {
            const pathCategory = location.pathname.substr('/shop/'.length).replace('laboratory', 'lab');
            const category = findCategoryName(pathCategory, categories);
            pageTitle = `${_.startCase(category)} for Medical Devices | PartsSource`;
            pageDescription = `Shop a wide selection of ${_.lowerCase(category)} for medical devices with PartsSource, the world’s largest provider of medical replacement products.`;
        } else if (modelName && oemName) {
            pageTitle = `${_.startCase(modelName)} Parts and Services from the PartsSource Marketplace`;
            pageDescription = `Find ${_.startCase(modelName)} OEM Original and Aftermarket Parts, Services and Repairs from PartsSource, the largest healthcare marketplace`
        } else if (oemName) {
            pageTitle = `${_.startCase(oemName.replace(/-/g, ' '))} Parts and Services from the PartsSource Marketplace`;
            pageDescription = `Find ${_.startCase(oemName.replace(/-/g, ' '))} OEM Original and Aftermarket Parts, Services and Repairs from PartsSource, the largest healthcare marketplace`
        }

        const routeInfo = {
            viewAll: urlParams._view && urlParams._view.toLowerCase() === 'all' ? true : false,
            page: page,
            pageSize: pageSize,
            isBusy: true,
            pageTitle,
            pageDescription,
        };

        const facilityId = (skipPricing && isLoggedIn)
            ? -1
            : isLoggedIn
                ? facility && facility.facilityId && facility.facilityId > 0 ? facility.facilityId : facilities[0].facilityId
                : 38451;

        if (cat1) {/* Category search to N levels*/
            return categorySearch({
                query: urlParams.q,
                start: pageSize * (page - 1),
                limit: pageSize,
                facets: facets,
                facilityId: facilityId,
                referer: referer,
                url,
                urlParams: convertToUrlParams(urlParams),
                match: request.match,
                id_ins: id_ins,
            }, request.location, routeInfo, pageSize, page, dispatch);
        } else if (oemName) {
            // If both the model and oem are specified, then we want to search for model only
            let routeFacets = [];
            if (modelName) {
                routeFacets.push({name: 'model_search', value: modelName});
            } else {
                routeFacets.push({name: 'oem_search', value: oemName});
            }
            return productSearch({
                query: urlParams.q ?? (modelName ? modelName : oemName),
                start: pageSize * (page - 1),
                limit: pageSize,
                routeFacets: routeFacets,
                facets: facets,
                facilityId: facilityId,
                url,
                id_ins: id_ins,
            }, request.location, routeInfo, dispatch);
        } else if (urlHasFacets || urlParams.q) {
            return productSearch({
                start: pageSize * (page - 1),
                limit: pageSize,
                facets: facets,
                query: urlParams.q,
                facilityId: facilityId,
                referer: referer,
                url,
                urlParams: convertToUrlParams(urlParams),
                id_ins: id_ins,
            }, request.location, routeInfo, dispatch);
        } else {
            const match = matchPath(window.location.pathname, {
                path: '(/site/_cmsinternal)/catalog',
                exact: true,
            });
            // TODO: Will there be a CMS landing page?
            if (!match) {
                //browserHistory.push('/');
            }
        }
    }
}

function productSearch(request, location, routeInfo, dispatch) {

    let apiRequest = _.merge({}, request);
    apiRequest.facets = _.union((apiRequest.facets || []), (apiRequest.routeFacets || []));

    let searchFacets = _.find(apiRequest.facets, x => x.name === 'sq');

    if (searchFacets) {
        _.remove(apiRequest.facets, x => x.name === 'sq');
        let sqFacets = decodeURIComponent(searchFacets.value).split('|');
        apiRequest.query = (apiRequest.query || '') + ' ' + sqFacets.join(' ');
    }

    const bound = {
        request: () => dispatch({type: 'PERFORM_SEARCH_REQ'}),
        response: response => dispatch({type: 'PERFORM_SEARCH_RESP', response: response, request: request, location: location, routeInfo: routeInfo}),
        error: error => dispatch({type: 'PERFORM_SEARCH_ERR', response: error}),
    };


    bound.request();
    return axios.post('/CatalogService/api/v1/search', apiRequest)
        .then(bound.response)
        .catch(bound.error);
}

function categorySearch(request, location, routeInfo, pageSize, page, dispatch) {
    const {referer, url, urlParams, match: {params: {cat1, cat2, cat3, cat4, cat5}}, facilityId, facets, id_ins} = request;

    const specifiedCats = [cat1, cat2, cat3, cat4, cat5].filter(c => c);
    const categoryId = specifiedCats.join('-');

    let routeFacets = [];
    routeFacets.push({name: 'category', value: categoryId});

    let apiRequest = {
        query: categoryId, // this is the category_id
        start: pageSize * (page - 1),
        limit: pageSize,
        facets: facets,
        routeFacets: routeFacets,
        facilityId: facilityId,
        referer: referer,
        url,
        urlParams: urlParams,
        id_ins: id_ins,
    };

    request.routeFacets = routeFacets;
    request.start = apiRequest.start;
    request.limit = pageSize;

    const bound = {
        request: () => dispatch({type: 'PERFORM_SEARCH_REQ'}),
        response: response => dispatch({type: 'PERFORM_SEARCH_RESP', response: response, request: request, location: location, routeInfo: routeInfo}),
        error: error => dispatch({type: 'PERFORM_SEARCH_ERR', response: error}),
    };


    bound.request();
    return axios.post(`/CatalogService/api/v1/search/category`, apiRequest)
        .then(bound.response)
        .catch(bound.error);
}

function saveCatalogState(changes) {
    return (dispatch, getState) => {
        let catalog = _.mergeWith(getState().catalog, changes, customizerKeepArray);
        dispatch({type: 'SAVE_CATALOG_STATE_RESP', catalog: catalog});
    };
}

function convertToFacets(urlParams) {
    return _.flatten(Object.keys(urlParams)
        .filter(k => k[0] !== '_' && k !== 'q')
        .map(key => {
            const fq = urlParams[key];
            if (_.isArray(fq)) {
                return fq.map(value => {
                    return { name: decodeURI( key), value: encodeURIComponent(value)}
                });
            } else {
                return { name: decodeURI( key), value: encodeURIComponent(fq)};
            }
        }));
}

function convertToUrlParams(urlParams) {
    return _.flatten(Object.keys(urlParams)
        .filter(k => k[0] !== 'q')
        .map(key => {
            const fq = urlParams[key];
            if (_.isArray(fq)) {
                return fq.map(value => {
                    return {name: key, value: value}
                });
            } else {
                return {name: key, value: fq};
            }
        }));
}

function findCategoryName(pathCategory, categories) {
    let category = categories.find(c => c.sluggedPath === pathCategory);
    if (category) return _.last(category.name.split('|'));

    return pathCategory.replace(/-/g, ' ');
}

function processSearchResponse(request, response, location) {
    const {data: {totalResults, products, facets, redirectUrl, autoCorrectedQuery, campaign}} = response;
    const filters = request.facets || [];

    const {_view} = getLocationQuery(location);

    if (redirectUrl && (!_view || _view !== 'all')) {
        return _.merge({}, _.cloneDeep(defaultCatalogState), {redirectUrl});
    } else {
        let crumbs = findCrumbs(request.routeFacets, products[0]);
        let newFacets = facets.slice(0, 20).map(f => {
            const itemCount = f.results.length;
            return {
                name: f.name,
                limit: facetExpansionCutoff,
                overflow: itemCount > facetExpansionCutoff ? itemCount - facetExpansionCutoff : 0,
                overflowMode: itemCount <= facetExpansionCutoff
                    ? 'none'
                    : itemCount > facetSearchCutoff
                        ? 'search' : 'items',

                items: f.results.map(r => {
                    return {
                        parentId: r.parentId,
                        range: r.range,
                        value: r.value,
                        count: r.count,
                        subFacets: r.subFacets,
                        crumb: r.crumb ? r.crumb.split('/').map((c, i, a) => i < 2 ? c : c.substr(a[i - 1].length + 1)).slice(1).join('/') : null,
                        checked: _.find(filters, {name: f.name, value: encodeURIComponent(r.range)}) ||
                            _.find(request.routeFacets, {value: slugify(r.range)}),
                    };
                }),
            };
        });

        const breadCrumbs = storeProductCrumbs(filters, request.routeFacets, crumbs, newFacets);

        return {
            searchTerm: autoCorrectedQuery || request.query,
            totalResults: totalResults,
            products: products,
            filters: filters,
            routeFacets: request.routeFacets || [],
            breadCrumbs: crumbs,
            facets: newFacets,
            campaign: campaign,
            isCategoryLandingPage: (request.routeFacets || []).filter(x => x.name === 'category').length > 0,
            showNoResults: totalResults === 0,
            productCrumbs: breadCrumbs,
            isBusy: false,
            pathname: location.pathname,
            search: location.search,
        };
    }
}

function findCrumbs(routeFacets, product) {
    let crumbs = [];

    if (routeFacets && routeFacets.length > 0 && product) {
        const oemSearch = _.find(routeFacets, {name: 'oem_search'});

        if (oemSearch) {
            crumbs.push({label: product.brand, url: `/catalog/${oemSearch.value}`, selected: false});

            const modelSearch = _.find(routeFacets, {name: 'model_search'});
            if (modelSearch) {
                const models = (product.models || []).filter(m => m && slugify(m) === modelSearch.value);
                const modelName = models && models.length > 0 ? models[0] : 'Not Found';
                crumbs.push({label: modelName, url: `/catalog/${oemSearch.value}/${modelSearch.value}`, selected: false});
            }
            _.last(crumbs).selected = true;
        }
    }

    if (crumbs.length > 0)
        crumbs.unshift({label: 'Home', url: '/'});

    return crumbs;
}

function storeProductCrumbs(filters, routeFacets, breadCrumbs, facets) {

    let combinedFilters = (filters || []).concat(routeFacets || []);

    let categoryFilter = combinedFilters.filter(x => x.name === 'category')[0];
    if (categoryFilter) {
        let selectedCat = facets.filter(x => x.name === 'Category')[0].items.filter(x => x.value === categoryFilter.value)[0];
        if (selectedCat) {
            let categoryCrumbs = getCategoryPathArray(selectedCat, facets);

            for (let i = 0; i < categoryCrumbs.length; i++) {
                categoryCrumbs[i].url = '/shop/' + categoryCrumbs[i].url;
                // categoryCrumbs[i].url = "/catalog?fq=category:" + categoryCrumbs[i].url;
            }

            if (breadCrumbs.length === 0 && categoryCrumbs.length > 0) {
                categoryCrumbs.unshift({url: '/', label: 'Home'});
            }

            if (categoryCrumbs.length > 0)
                breadCrumbs = breadCrumbs.concat(categoryCrumbs);
        }
    }

    return breadCrumbs;
}

function getCategoryPathArray(facet, facets) {
    let catPath = [];
    catPath.push({label: facet.range, url: facet.value, crumb: facet.crumb});

    if (facet.parentId) {
        let search = true;
        let parentId = facet.parentId;

        while (search) {
            let parent = facets.find(x => x.name === 'Category').items.find(x => x.value === parentId);

            if (parent) {
                catPath.unshift({label: parent.range, url: parent.value, crumb: parent.crumb});
                parentId = parent.parentId;
            } else
                search = false;
        }
    }

    return catPath;
}

function customizerKeepArray(objValue, srcValue) {
    if (_.isArray(objValue)) {
        return objValue;
    }
}

function customizerReplaceArray(objValue, srcValue) {
    if (_.isArray(srcValue)) {
        return srcValue;
    }
}

function resetCatalog(){
    let newState = _.cloneDeep(defaultCatalogState);
    return dispatch => dispatch({type: 'RESET_CATALOG', newState: newState});
}

export const reducer = (state = _.cloneDeep(defaultCatalogState), action = null) => {
    switch (action.type) {
        case 'PERFORM_SEARCH_REQ':{
            return {...state, isBusy: true};
        }
        case 'PERFORM_SEARCH_RESP': {
            return _.mergeWith({}, state, action.routeInfo, processSearchResponse(action.request, action.response, action.location), customizerReplaceArray);
        }
        case 'CATALOG_SEARCH_RESP': {
            return _.mergeWith({}, state, action.routeInfo, processSearchResponse(action.request, action.response, action.location), customizerReplaceArray);
        }
        case 'SAVE_CATALOG_STATE_RESP': {
            return action.catalog;
        }
        case 'RESET_CATALOG': {
            state.products = [];
            state.breadCrumbs = [];
            state.filters = [];
            state.routeFacets = [];
            state.facets = [];
            state.filtersMenuVisibleSections = [];
            state.categoryRoots = [];
            return _.mergeWith({}, state, action.newState);
        }
        case 'PERFORM_SEARCH_ERR':{
            const data = action;
            return {...state, isBusy: false};
        }
    }

    return state;
};
