import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

const Container = styled.div`
    flex
`;

const Table = styled.table`
    background-color: white;
    min-width: 750px;
    max-width: 100%;
    width: 100%;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;

    tr {
        &:nth-of-type(odd) {
            background-color: #F1F1F1;
        }
    }

    th {
        font-size: 16px;
        font-weight: 600;
        padding: 15px;
        background-color: white;
        color: #4a4a4a;
        cursor: pointer;

        &:not(:last-of-type) {
            border-right: 1px solid #ddd;
        }

        span {
            font-size: 20px;
            float: right;
        }
    }

    td {
        padding: 10px 15px;
        border-top: 1px solid #ddd;
        font-size: 16px;

        &:not(:last-of-type) {
            border-right: 1px solid #ddd;
        }
    }
`;


export default function ProductTabTable({columns, data, defaultSortColumnId}) {
    const [sortAsc, setSortAsc] = useState(true);
    const [sortColumnId, setSortColumnId] = useState(defaultSortColumnId);
    const [sortedData, setSortedData] = useState([]);

    useEffect(() => {
        setSortedData(_.orderBy(data, sortColumnId, sortAsc ? 'asc' : 'desc'));
    }, [sortColumnId, sortAsc])

    const applySort = (c) => {
        if(c.id == sortColumnId) {
            setSortAsc(!sortAsc);
        } else {
            setSortColumnId(c.id);
            setSortAsc(true);
        }
    }

    return (
       <Container>
            <Table>
              <tr>
                {columns.map(c => 
                    <th onClick={() => applySort(c)}>
                        {c.name} 
                        {c.id == sortColumnId ? sortAsc ? <span>&#8593;</span> : <span>&#8595;</span> : null}
                    </th>)}              
              </tr>
              {sortedData.map(d => 
                <tr>
                    {columns.map(c => <td>{d[c.id]}</td>)}
                </tr>)
              }
            </Table>
       </Container>
    )
}