import * as React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import _ from 'lodash';
import {slugify} from 'utility';
import {getLineItemWarranty, getPartCondition, getPurchaseChoice, getVendorAttribute} from 'productUtility';
import * as NetworkStore from 'stores/Network';
import * as UserStore from 'stores/User';
import * as SystemStore from 'stores/System';
import {Accordion} from '@partssourceinc/react-ui-core';
import ProductTabTable from './ProductTabTable';
import 'less/productTabs.less';
import {PhonePortrait, Default} from 'responsive';

const TabNames = {
    details: 'Features',
    fitments: 'Fitments',
    compatibles: 'Equivalents',
    technicalSpecs: 'Technical Specs',
    productOverview: 'Product Overview',
    certifications: 'Certifications',
};

@connect(state => ({network: state.network, system: state.system, user: state.user}))
export default class ProductTabs extends React.Component {
    static propTypes = {
        product: PropTypes.object.isRequired,
        option: PropTypes.object.isRequired,
        network: NetworkStore.StateShape,
        user: UserStore.StateShape,
        system: SystemStore.StateShape,
    };

    constructor(props) {
        super(props);
        
        this.state = {
            isPageLoaded: false,
            equivalentColumns: [
                {id: 'oemName', name: 'OEM'}, 
                {id: 'displayPartNumber', name: 'OEM Part Number'},
                {id: 'purpose', name: 'Details'}
            ],
            fitmentColumns: [
                {id: 'oemName', name: 'OEM'}, 
                {id: 'displayPartNumber', name: 'OEM Part Number'},
            ]
        }
    }

    componentDidMount() {
        this.setState({isPageLoaded: true});
    }

    renderDetails() {
        const {
            product,
            option,
            network: {isLoggedIn},
            system: {siteSettings: {qxEnableCollectListingFeedback}},
            onPricingFeedback,
            user
        } = this.props;

        const featureAttributes = _.sortBy((option.attributes ?? product.productAttributes ?? []).filter(o => o.purpose === 'FEATURE'), ['sequence','name']);
        const displayPricingFeedback = (typeof(onPricingFeedback) !== 'undefined');
        const feedbackVisibility = isLoggedIn && qxEnableCollectListingFeedback && displayPricingFeedback ? true : false;

        return (<div>
            <ul>
                {(featureAttributes || []).map(a => <li key={`${a.name}`}>{a.value}</li>)}
            </ul>
            {displayPricingFeedback && <div className="listing-feedback-container" style={{visibility: feedbackVisibility ? 'visible' : 'hidden'}}>
                How can we <div onClick={() => onPricingFeedback()} className="listing-feedback">improve our item listing</div>?
            </div>}
        </div>);
    }

    renderCerts(classPrefix, option) {
        return (
            <div className={`${classPrefix}_certs`}>
                {Object.keys(option.certifications).map((c, index) => (
                    <span className="cert-wrapper">
                        <img key={`img_cert_${index}`} src={`/images/${getVendorAttribute(c)}`} alt={option.certifications[c]} />
                        <span className='cert-tooltip'>{option.certifications[c]}</span>
                    </span>
                ))}
            </div>
        );
    }

    renderCompatibleParts(compatibleParts) {
        if(!compatibleParts) return null;

        return (<div>
            <ul>
                {compatibleParts.map((p, index) => (
                    <li key={`cp-${index}`}> {p.displayPartNumber}: {p.oemName}{p.purpose ? ':' : ''} {p.purpose}</li>
                ))}
            </ul>
        </div>);
    }

    renderModels(models) {
        const {product} = this.props;
        const {visibleSection} = this.state;
        if(!models) return null;
        return (<div>
            {visibleSection === 3 ? (
                <ul>
                    {Object.keys(models).map(oem => (
                        <li key={oem}>
                            {oem}
                            <ul>
                                {models[oem].map(m => <NavLink key={`lnk_${m}`} to={`/catalog/${slugify(product.manufacturer)}/${slugify(m)}`} title={m}> <li key={m}>{m}</li> </NavLink>)}
                            </ul>
                        </li>
                    ))}
                </ul>
            ) : (
                <ul>
                    {Object.keys(models).map(oem => (
                        <li key={oem}>
                            {oem}
                            <ul>
                                {models[oem].map(m => <li key={m}>{m}</li>)}
                            </ul>
                        </li>
                    ))}
                </ul>
            )}
        </div>);
    }

    renderTechSpecs() {
        const {product, option} = this.props;
        const technicalAttributes = _.sortBy((option.attributes ?? product.productAttributes ?? []).filter(o => o.purpose === 'TECHNICAL SPEC' && o.name.toLowerCase() !== 'prop65c' && o.name.toLowerCase() !== 'prop65r'), ['sequence','name']);
        const description = option.description || product.description;
        
        return (<div>
            <div>
                <div className="product-info_table">
                    <h3>P/N: {product.displayPartNumber} Key Specs</h3>
                    <div className="product-info_row">
                        <div className="lbl">Item</div>
                        <div className="lbl">{description}</div>
                    </div>
                    <div className="product-info_row">
                        <div className="lbl">OEM</div>
                        <div className="lbl">{product.manufacturer}</div>
                    </div>
                    <div className="product-info_row">
                        <div className="lbl">OEM #</div>
                        <div className="lbl">
                            {product.displayPartNumber}
                        </div>
                    </div>
                    {option.lineItemCondition ? (
                        <div className="product-info_row">
                            <div className="lbl">Condition</div>
                            <div className="lbl">
                                {getPartCondition(option.lineItemCondition)}
                            </div>
                        </div>
                    ) : null}
                    <div className="product-info_row">
                        <div className="lbl">Returnable</div>
                        <div className="lbl">
                            {option.isReturnable ? 'Yes' : 'No'}
                        </div>
                    </div>
                    {technicalAttributes.map((ta, index) => (
                        <div key={`ta_${index}`} className="product-info_row">
                            <div className="lbl">{ta.name}</div>
                            <div className="lbl lbl-bold">{ta.value}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>);
    }

    renderProductOverview() {
        const {option} = this.props;

        return (<div>
            <p dangerouslySetInnerHTML={{__html: option.productOverview}} />
        </div>);
    }

    render() {
        const {isPageLoaded, equivalentColumns, fitmentColumns} = this.state;
        const {product, option, user} = this.props;

        const models = product.models && Object.keys(product.models).length > 0 ? product.models : null;
        const fitmentList = product.models ? _.flatMap(Object.keys(product.models).map(o => product.models[o].map(x => ({oemName: o, displayPartNumber: x})))) : null;
        const showVendorCertifications = (user.facilities||[]).length === 0 || (user.facilities||[]).every(x => x.showVendorCertifications) || (user.facility && user.facility.showVendorCertifications);
        const certifications = showVendorCertifications && option.certifications && Object.keys(option.certifications).length > 0 ? option.certifications : null;
        const featureAttributes = _.sortBy((option.attributes ?? product.productAttributes ?? []).filter(o => o.purpose === 'FEATURE'), ['sequence','name']) || [];

        return (<React.Fragment>
            {option.productOverview && <Accordion title={TabNames.productOverview} open={true} autoScroll={false} deferAnimation={true}>
                {this.renderProductOverview()}
            </Accordion>}

            {featureAttributes.length > 0 && <Accordion title={TabNames.details} open={true} autoScroll={false} deferAnimation={true}>
                {this.renderDetails()}
            </Accordion>}

            <Accordion open={true} title={TabNames.technicalSpecs} autoScroll={false} deferAnimation={true}>
                {this.renderTechSpecs()}
            </Accordion>

            {product.oemCompatibleParts &&  product.oemCompatibleParts.length > 0 && <Accordion title={TabNames.compatibles} open={false} autoScroll={false} deferAnimation={true}>
                <PhonePortrait>{this.renderCompatibleParts(product.oemCompatibleParts)}</PhonePortrait>
                <Default><ProductTabTable columns={equivalentColumns} data={product.oemCompatibleParts} defaultSortColumnId={'oemName'} /></Default>
            </Accordion>}

            {models && <Accordion title={TabNames.fitments} open={false} autoScroll={false} deferAnimation={true}>
                <PhonePortrait>{this.renderModels(models)}</PhonePortrait>
                <Default><ProductTabTable columns={fitmentColumns} data={fitmentList} defaultSortColumnId={'oemName'} /></Default>
            </Accordion>}

            {certifications && <Accordion title={TabNames.certifications} open={true} autoScroll={false} deferAnimation={true}>
                {this.renderCerts('product', option)}
            </Accordion>}

            {!isPageLoaded && <Accordion open={true} autoScroll={false}>
                {option.productOverview && this.renderProductOverview()}
                {product.oemCompatibleParts && this.renderCompatibleParts(product.oemCompatibleParts)}
                {models && this.renderModels(models)}
            </Accordion>}
        </React.Fragment>);
    }
}
