import axios from 'axios';
import Cookies from 'js-cookie';
import _ from 'lodash';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import {months} from 'data/DatesData';
import {defaultCartState} from 'stores/Cart';

export function getCmsImageUrlByPath(imagePath) {
    return process.env.REACT_APP_CMS_URL + `/site/binaries${imagePath}`;
}

export function logEvent(eventTypeCode, payload, eventTypeId = 99) {
    const info = _.merge({}, {id_ins: getCookie('id_ins')}, payload);

    return axios.post(`/CatalogService/api/v1/log/${eventTypeId}/${eventTypeCode}`,
        Object.keys(info).map(function(key) {
            return {name: key, value: info[key]};
        }));
}

export function logEventVerbose(eventTypeCode, payloadArray, eventTypeId = 99) {
    return axios.post(`/CatalogService/api/v1/log/${eventTypeId}/${eventTypeCode}`, payloadArray);
}

export function isPsPartNumber(str) {
    return /^(PS)[a-zA-Z0-9_.-]*$/i.test(str);
}

export function slugify(text) {
    return text.toString().replace(/\s+/g, '-').replace(/\//g, '-').replace(/[^\w-]+/g, '').replace(/--+/g, '-').replace(/^-+/, '').replace(/-+$/, '').toLowerCase();
}

export function classNames(names) {
    return _.join(_.keys(_.pickBy(names, function(value) {
        return value === true;
    })), ' ');
}

export function guid() {
    const S4 = () => {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1); 
    }
    return (S4() + S4() + '-' + S4() + '-4' + S4().substring(0,3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase();
}

// excludeDecimals is an optional property
export function formatMoney(number, excludeDecimals) {
    if (!number || number === 'NAN') {
        number = 0;
    }

    let numberConfig = {
        style: 'currency',
        currency: 'USD',
    }

    if (excludeDecimals) {
        numberConfig = {
            ...numberConfig,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }
    }

    return number.toLocaleString('en-US', numberConfig);
}

export function isQuotedRepair(number) {
    return number === 23 || number === 25;
}

export function getLoanerString() {
    return ' - Loaner';
}

export function getFormattedPhoneNum(input) {
    // we should pass a string including each phone's digit (NO phone code (1 or +1), NO symbols)

    let output = '1 (';

    if (!input) return output;

    input.replace(/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function(match, g1, g2, g3) {
        if (g1.length) {
            output += g1;
            if (g1.length === 3) {
                output += ')';
                if (g2.length) {
                    output += ' ' + g2;
                    if (g2.length === 3) {
                        output += '-';
                        if (g3.length) {
                            output += g3;
                        }
                    }
                }
            }
        }
    });

    // return the formatted number with the phone code (1) e.g.  "1 (123) 456-7899"
    return output;
}

export function getFormattedPhoneNumber(input) {
    // we should pass a string including each phone's digit (NO phone code (1 or +1), NO symbols)

    let output = '';

    if (!input) return output;

    input.replace(/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function(match, g1, g2, g3) {
        if (g1.length) {
            output += g1;
            if (g1.length === 3) {
                output += '-';
                if (g2.length) {
                    output += g2;
                    if (g2.length === 3) {
                        output += '-';
                        if (g3.length) {
                            output += g3;
                        }
                    }
                }
            }
        }
    });

    // return the formatted number without the phone code (1 or +1) e.g. "123-456-7899"
    return output;
}

export function stripAwayPhoneNumberSymbols(formatted) {
    // we strip away everything apart from the phone's digits

    if (!formatted) return;

    return formatted.replace(/[^0-9]/g, '');
}

export function stripAwayPhoneNumberCode(num) {
    // we strip away the starting code (1, or +1)
    let phone;

    if (num.startsWith('1')) {
        phone = num.substring(1);
    } else if (num.startsWith('+1')) {
        phone = num.substring(2);
    } else {
        phone = num;
    }

    return phone;
}

export function getTrackingNumbersUrlsData(trackingNumbers, lineItemId) {
    if (!trackingNumbers) {
        return null;
    }

    let urlsData = [];
    let fedexNumbers = [];
    let upsNumbers = [];

    _.forEach(trackingNumbers, function(det) {
        if (det.isNumberValid) {
            if (det.carrier === 1) {
                fedexNumbers.push(det.number);
            } else if (det.carrier === 2) {
                upsNumbers.push(det.number);
            }
        } else {
            logEvent('Tracking Filtered', {
                'LineItemId': lineItemId,
                'Tracking Number Filtered': det.number,
                'Reason': `Could not Determine Carrier`,
            });
        }
    });

    if (fedexNumbers.length > 0) {
        let fedexNumbersJoined = fedexNumbers.join(',');

        urlsData.push({
            url: getTrackingUrl(fedexNumbersJoined, 'FedEx'),
            number: fedexNumbersJoined,
        });
    }

    if (upsNumbers.length > 0) {
        _.forEach(upsNumbers, function(upsNum) {
            urlsData.push({
                url: getTrackingUrl(upsNum, 'UPS'),
                number: upsNum,
            });
        });
    }

    return urlsData;
}

export function getTrackingUrl(trackingNumber, shipViaId) {
    if (!trackingNumber) return null;
    if (shipViaId == 1 || shipViaId === 'FedEx') {
        return 'https://www.fedex.com/fedextrack/?trknbr=' + trackingNumber;
    } else if (shipViaId == 2 || shipViaId === 'UPS') {
        return 'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=' + trackingNumber;
    }
    return null;
}

export function addToCookieIfExclusiveSupplier(oemName) {
    axios.get(`/CatalogService/api/v1.0/isExclusive/${oemName}`).then(response => {
        if (response.data.isExclusive) {
            let eoem = getCookie('eoem');

            if (eoem !== '') {
                let oldEoems = eoem.split(',');

                if (!_.includes(oldEoems, ('' + response.data.oemId))) {
                    oldEoems.push(response.data.oemId);
                    setCookie('eoem', oldEoems);
                }
            } else {
                setCookie('eoem', response.data.oemId);
            }
        }
    });
}

export function getLocationQuery(location) {
    const search = (location.search || '').substring(1);
    let result = {};

    if (search) {
        const cleaned = search;

        _.forEach(_.split(cleaned, '&'), kvp => {
            let parts = _.split(kvp, '=');
            const key = parts[0];
            const value = decodeURIComponent(parts[1]);

            if (result[key]) {
                let current = result[key];
                if (_.isArray(current)) {
                    current.push(value);
                } else {
                    result[key] = [current, value];
                }
            } else {
                result[key] = value;
            }
        });
    }
    return result;
}

export function isURL(str) {
    if (!str) return false;
    return str.toLowerCase().indexOf('https:') >= 0 || str.toLowerCase().indexOf('http:') >= 0;
}

export function loadState() {
    try {
        const cachedState = localStorage.getItem('appState');
        if (cachedState) {
            let appState = JSON.parse(cachedState);
            appState.cart = _.merge(_.cloneDeep(defaultCartState), {itemCount: appState.cart.itemCount});
            appState.user.info = {};
            appState.user.settings = {};

            return appState;
        }
    } catch (err) {
    // log error
    }
    return undefined;
}

export function sortByKey(array, key) {
    return array.sort(function(a, b) {
        let x = a[key];
        let y = b[key];

        if (typeof x === 'string') {
            x = ('' + x).toLowerCase();
        }
        if (typeof y === 'string') {
            y = ('' + y).toLowerCase();
        }

        return x < y ? -1 : x > y ? 1 : 0;
    });
}

const arrayMoveMutate = (array, from, to) => {
    const startIndex = to < 0 ? array.length + to : to;
    const item = array.splice(from, 1)[0];
    array.splice(startIndex, 0, item);
};

export function arrayMove (array, from, to) {
    array = array.slice();
    arrayMoveMutate(array, from, to);
    return array;
}

export function arrayMoveMultiGrid(arr, fromIndex, toIndex) {
    let element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}

/**
 * refreshToken
 *
 * @param {boolean} [forceRefresh=false] Force a token refresh or not
 *
 * @returns {Promise} A promise that resolves when the token is refreshed (or an error was thrown)
 */
export function refreshToken(forceRefresh) {
    /*
     * Force default parameter value
     */
    forceRefresh = (typeof forceRefresh !== 'undefined') ? forceRefresh : false;
    return new Promise(function (resolve) {
        let hasToken = Cookies.get('token');
        if (hasToken) {
            let tokenExpiration = Cookies.get('tokenExpiration');
            let refreshT = Cookies.get('refreshToken');
            try {
                if (moment().isBefore(moment.unix(tokenExpiration)) && !forceRefresh) {
                    resolve();
                } else {
                    const tokenRequest = `client_id=owner&client_secret=secret&grant_type=refresh_token&refresh_token=${refreshT}`;

                    axios.post('/AuthenticationService/connect/token', tokenRequest, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).then(function (x) {
                        const {status, data: {token_type, access_token, refresh_token, expires_in}} = x;
                        if (status === 200) {
                            const bearerToken = `${token_type} ${access_token}`;

                            setToken(bearerToken, refresh_token, expires_in);
                        } else {
                            console.log('Status Error');
                            clearTokenInfo();
                        }
                        resolve();
                    }).catch(function (error) {
                        console.log(error);
                        clearTokenInfo();
                        resolve();
                    });
                }
            } catch (err) {
                console.log(err);
                clearTokenInfo();
                resolve();
            }
        } else {
            clearTokenInfo();
            resolve();
        }
    }).catch(function (error) {
        console.log(error);
    });
}

export function clearToken() {
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    Cookies.remove('tokenExpiration');
    Cookies.remove('employee');
    Cookies.remove('facilityId');
    Cookies.remove('employeeFacility');
    Cookies.remove('subscriptionId');
    Cookies.remove('rememberMe');
    Cookies.remove('impersonate');
    Cookies.remove('eoem');
    Cookies.remove('sisense');
    Cookies.remove('requesterViewType-onSiteServiceColumns');
    Cookies.remove('requesterViewType-myOrdersColumns');
    Cookies.remove('requesterViewType-repairsColumns');
    Cookies.remove('requesterViewType-rgaColumns');
    Cookies.remove('requesterViewType-quotesColumns');    
}

export function clearSisenseCookie() {
    Cookies.remove('sisense');
}

export function setToken(bearerToken, refresh_token, expires_in) {
    let expireNumericValue = parseInt(moment().add(expires_in, 'seconds').format('X'));
    const tokenExpiration = Cookies.get('rememberMe') === 'true' ? 1 : undefined;
    const in8Hours = new Date(new Date().getTime() + 8 * 60 * 60 * 1000);
    console.log('In 8 Hours ' + in8Hours);
    const refreshTokenExpiration = Cookies.get('rememberMe') === 'true' ? in8Hours : undefined;
    const isNotLocalhost = window.location.hostname !== 'localhost';
    Cookies.set('token', bearerToken, {
        expires: tokenExpiration,
        secure: isNotLocalhost,
        sameSite: isNotLocalhost ? 'None' : undefined,
    });
    Cookies.set('refreshToken', refresh_token, {
        expires: refreshTokenExpiration,
        secure: isNotLocalhost,
        sameSite: isNotLocalhost ? 'None' : undefined,
    });
    Cookies.set('tokenExpiration', expireNumericValue, {
        expires: tokenExpiration,
        secure: isNotLocalhost,
        sameSite: isNotLocalhost ? 'None' : undefined,
    });
}

export function clearTokenInfo() {
    clearToken();
}

export function setCookie(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function getCookie(cname) {
    try {
        let name = cname + '=';
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    } catch (e) {
        return '';
    }
}

export function deleteCookie(name) {
    // If the cookie exists
    if (getCookie(name))
        setCookie(name, '', -1);
}

// Better version would be ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,a=>(a^crypto.getRandomValues(new Uint8Array(1))[0]).toString(16))
export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16 | 0,
            v = c === 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
    });
}

export function mergeByProperty(arr1, arr2, prop) {
    if (!arr1) arr1 = [];
    if (!arr2) arr2 = [];

    _.each(arr2, function (arr2obj) {
        let arr1obj = _.find(arr1, function (arr1Item) {
            return arr1Item[prop] === arr2obj[prop];
        });

        // If the object already exist extend it with the new values from arr2, otherwise just add the new object to arr1
        if (arr1obj) {
            _.extend(arr1obj, arr2obj);
        } else {
            arr1.push(_.merge({}, arr2obj, {vendorFieldOnly: true}));
        }
    });
}

// https://levelup.gitconnected.com/debounce-in-javascript-improve-your-applications-performance-5b01855e086
export function debounce(func, delay, immediate) {
    let delayedFunction;
    let result;

    return function() {
        function executeFunction() {
            delayedFunction = null;
            if (!immediate) result = func.apply(boundThis, args);
        }

        const boundThis = this;
        const args = arguments;

        clearTimeout(delayedFunction);
        delayedFunction = setTimeout(executeFunction, delay);
        if (immediate && !delayedFunction) result = func.apply(boundThis, args);

        return result;
    };
}

export function isValidListName(str) {
    return !/[~`!#$%^&*+=\-[\]\\;,/{}|\\":<>?]/g.test(str);
}

export function pathJoin(...paths) {
    return paths.reduce((acc, p) => `${acc}/${p}`).replace(/\/{2,}/g, '/');
}

export function pathMatches(path, pathname) {
    const stripSlashes = s => s.replace(/(.+)\/$/g, '$1');
    return stripSlashes(path) === stripSlashes(pathname);
}
export function pageView(referer) {
    const referrerPage = document.referrer;
    const canonical = document.querySelector('link[rel="canonical"]');

    let qs = require('qs');
    let query = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
    });

    let ua = window.navigator.userAgent;

    let info = {

        browser: /Edge\/\d+/.test(ua) ? 'Edge' : /MSIE 9/.test(ua) ? 'IE' : /MSIE 10/.test(ua) ? 'IE10' : /MSIE 11/.test(ua) ? 'IE11' : /MSIE\s\d/.test(ua) ? 'IE?' : /rv\:11/.test(ua) ? 'IE11' : /Firefox\W\d/.test(ua) ? 'Firefox' : /Chrom(e|ium)\W\d|CriOS\W\d/.test(ua) ? 'Chrome' : /\bSafari\W\d/.test(ua) ? 'Safari' : /\bOpera\W\d/.test(ua) ? 'Opera' : /\bOPR\W\d/i.test(ua) ? 'Opera' : typeof MSPointerEvent !== 'undefined' ? 'IE?' : '',
        device: /Mobi/.test(ua) ? 'Mobile' : 'Desktop',
    };

    const prevUrl = referrerPage !== '' ? referrerPage : referer !== undefined ? window.location.origin + referer : window.location.origin;
    const url = canonical !== null ? canonical.getAttribute('href') !== '' ? canonical.getAttribute('href') : window.location.href : window.location.href;
    let logDataDetails = [];
    logDataDetails.push({name: 'id_ins', value: getCookie('id_ins')});
    logDataDetails.push({name: 'Referrer', value: prevUrl});
    logDataDetails.push({name: 'Full Page URL', value: url});
    logDataDetails.push({name: 'Root Page URL', value: window.location.hostname});
    logDataDetails.push({name: 'Page Path', value: window.location.pathname});

    if (referer != null && (referer.includes('?utm_') || referer.includes('&utm_'))) {
        const search = referer.split('?').pop();
        query = qs.parse(search, {
            ignoreQueryPrefix: true,
        });
        logDataDetails.push({name: 'Page Query String', value: search});
    } else
        logDataDetails.push({name: 'Page Query String', value: window.location.search});

    query & Object.keys(query).map(key => {

        logDataDetails.push({name: `${key}`, value: `${query[key]}`})

    });

    logDataDetails.push({name: 'Browser Name', value: info.browser});
    logDataDetails.push({name: 'UA Device Type', value: info.device});
    logEventVerbose('Page View', logDataDetails);

}

export function translateHomeScreen(number, hasDashboardv2Access = false) {
    if (number == 2 || (number == 7 && !hasDashboardv2Access))
        return '/';

    return {
        '1': '/quotes?tab=quoted',
        '2': '/dashboard',
        '3': '/orders/history',
        '7': '/command-center',
        // 4 = action items
        // 5 = reports
        '6': '/approvals',
        // 8 - catalog
        '9': '/orders/po',
    }[number.toString()] || '/';
}

export function translateRedirect(query, match, defaultHomeScreen) {
    try {
        let redirectUrl = '';
        let matchUrl = '';

        let orderHistory = '/orders/history';
        let rga = '/orders/rga';
        let order = '/order';
        let catalog = '/catalog';

        let oldQuotes = 'quotes';
        let orderDetails = 'orderdetails.aspx';
        let myOrders = 'myorders';
        let historyLegacy = 'history.aspx';
        let history = 'historyorders.aspx';
        let actionItems = 'actionitems.aspx';
        let catalogDefault = 'default.aspx';
        let newOrder = 'neworder.aspx';
        let ordersPages = [myOrders, history, orderDetails];

        if (match && match.params && match.params.id) {
            matchUrl = match.params.id.toLowerCase();
            switch (matchUrl) {
                case oldQuotes:
                    // quotes route are valid in both sites. This is going to cause a bug
                    // with an unsupported lineItemId, but that can be fixed on the quotes
                    // redirectUrl = quotes;
                    break;
                case myOrders:
                case historyLegacy:
                case history:
                    if (query.lineItemId || query.orderId) {
                        redirectUrl = order;
                    } else {
                        redirectUrl = orderHistory;
                    }
                    break;
                case actionItems:
                    redirectUrl = rga;
                    break;
                case orderDetails:
                    redirectUrl = order;
                    break;
                case catalogDefault:
                    return translateHomeScreen(defaultHomeScreen);
                case newOrder:
                    redirectUrl = catalog;
                    break;
                case 'partimage.aspx':
                    redirectUrl = order + '/' + query.lineItemId
                    break;
                case 'approveorders.aspx':
                    redirectUrl = '/approvals';
                    break;
            }
        }

        if (query) {
            if (query.page && query.page === 'exchangeOrders') redirectUrl += '?tab=exchanges';
            if (query.page && query.page === 'returnOrders') redirectUrl += '?tab=returns';
            if (query.lineItemId && ordersPages.includes(matchUrl)) redirectUrl += '/' + query.lineItemId;
            if (query.orderId && ordersPages.includes(matchUrl)) redirectUrl += '/?orderId=' + query.orderId;
            // Remove per B, if (query.model) redirectUrl += "/?q=" + query.model;
        }

        return redirectUrl;
    } catch (err) {
        console.log('Translate Error - ' + err);
        return '';
    }
}

export function objectToPropTypes(state) {
    return PropTypes.shape(Object.entries(state).reduce((result, [key, value]) => {
        switch (typeof value) {
            case 'boolean':
                return {...result, [key]: PropTypes.bool.isRequired};
            case 'function':
                return {...result, [key]: PropTypes.func.isRequired};
            case 'number':
            case 'bigint':
                return {...result, [key]: PropTypes.number.isRequired};
            case 'string':
                return {...result, [key]: PropTypes.string.isRequired};
            case 'object':
                if (_.isArray(value)) {
                    // There is no way to detect what the type is of the
                    // items in an empty array. If we want this to be more
                    // specific, we should use Typescript
                    return {...result, [key]: PropTypes.array.isRequired};
                } else if (!value) {
                    return {...result, [key]: PropTypes.object};
                } else {
                    return {...result, [key]: objectToPropTypes(value)};
                }
            default:
                return result;
        }
    }, {}));
}

export function removeParamFromUrl(key, sourceURL) {
    if (!sourceURL) return;
    let rtn = sourceURL.split('?')[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf('?') !== -1) ? sourceURL.split('?')[1] : '';
    if (queryString !== '') {
        params_arr = queryString.split('&');
        for (let i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split('=')[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        rtn = rtn + '?' + params_arr.join('&');
    }
    return rtn;
}

export function isLocalStorageAvailable() {
    const randomKey = 'test_random_key';
    try {
        localStorage.setItem(randomKey, randomKey);
        localStorage.removeItem(randomKey);
        return true;
    } catch (e) {
        return false;
    }
}

export function isSessionStorageAvailable() {
    const randomKey = 'test_random_key';
    try {
        sessionStorage.setItem(randomKey, randomKey);
        sessionStorage.removeItem(randomKey);
        return true;
    } catch (e) {
        return false;
    }
}

export function queryString(search) {
    return Object.fromEntries(search.slice(1).split('&').map(x => x.split('=')));
}

export function validateUPSAccountNumber(accountNumber) {
    if (!accountNumber)
        return false;

    let account = accountNumber.replace(/[^a-zA-Z0-9]/g, '');

    return /^[a-zA-Z0-9]{6}$/.test(account)
}

export function validateFedExAccountNumber(accountNumber) {
    if (!accountNumber)
        return false;
    let account = accountNumber.replace(/[^a-zA-Z0-9]/g, '');
    return /^[a-zA-Z0-9]{9}$/.test(account)
}

export function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;

    return strTime;
}

export function formatDateToString(date) {
    let dateObj = new Date(date);
    let repairDateWithYearMonthName = months[dateObj.getMonth()];

    return repairDateWithYearMonthName + ' ' + dateObj.getDate() + ' ' + dateObj.getFullYear()
        + '\r\n' + formatAMPM(dateObj);
}

export function formatDateToStringDate(date) {
    let dateObj = new Date(date);
    let repairDateWithYearMonthName = months[dateObj.getMonth()];

    return repairDateWithYearMonthName + ' ' + dateObj.getDate() + ' ' + dateObj.getFullYear();
}

export function formatDateToStringTime(date) {
    let dateObj = new Date(date);

    return formatAMPM(dateObj);
}

export function getDayMonth(date) {
    let dateObj = new Date(date);

    return ('0' + (dateObj.getMonth() + 1)).slice(-2) + '/' + ('0' + dateObj.getDate()).slice(-2);
}

export function getMonthDayYear(date) {
    let dateObj = new Date(date);

    return ('0' + (dateObj.getMonth() + 1)).slice(-2) + '/' + ('0' + dateObj.getDate()).slice(-2) + '/' + dateObj.getFullYear();
}

const purchaseTypes = [
    {
        value: 0,
        text: 'All',
    },
    {
        value: 1,
        text: 'Outright',
    },
    {
        value: 2,
        text: 'Exchange',
    },
];

export function getPurchaseTypeDisplay(intType) {
    return purchaseTypes.find(x => x.value == intType);
}

export const sortItems = (items, direction, column) => {
    return items.sort(function (a, b) {
        if (direction === 'desc') {
            return b[column] - a[column];
        } else {
            return a[column] - b[column];
        }
    });
}

export const getPercentage = (items, value, column) => {
    if (!value && !items?.length) {
        return 0;
    }
    const val = items[0][column];

    if (val == 0) return 0;
    
    const percentage = value * 100 / val;
    return percentage;
}

export const getPODLetter = (lineItemId, user, originScreen) => {
    return new Promise(function (resolve) {
        logEvent('Delivered Documentation', {
            userId: user.userId,
            uuid: user.loginId,
            linkLocation: window.location.href,
            originScreen,
        })
        
        axios.get(`ShoppingService/api/v1.0/lineItems/podletter?lineItemId=${lineItemId}`, {responseType: 'arraybuffer', headers: {'Content-Type': 'application/json', Accept: 'application/pdf'}}).then((x) => {
            const data = x.data;
            const file = new Blob([data], {type: 'application/pdf'});

            if (file.size === 0 || !x.status === 200) {
                resolve(false);
                return;
            }

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file);       
            } else {
                const fileURL = URL.createObjectURL(file);
                let link = document.createElement('a');
                link.type = 'hidden';
                link.href = fileURL;
                link.download = `PODLetter_${lineItemId}.pdf`;
                document.body.appendChild(link);
                link.click();
              
                setTimeout(function () {
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(fileURL);
                }, 100);
            }

            resolve(true);
        }).catch(function (error) {
            resolve(false);
        });
    }); 
}

export const getFSRDocument = (doc) => {
    return new Promise(function (resolve) {               
        axios.post(`ShoppingService/api/v1.0/lineItems/fsrDocument`, doc, {responseType: 'arraybuffer', headers: {'Content-Type': 'application/json', Accept: 'application/pdf'}}).then((x) => {
            const data = x.data;
            const file = new Blob([data], {type: 'application/pdf'});

            if (file.size === 0 || !x.status === 200) {
                resolve(false);
                return;
            }

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file);       
            } else {
                const fileURL = URL.createObjectURL(file);
                let link = document.createElement('a');
                link.type = 'hidden';
                link.href = fileURL;
                link.download = doc.fileName;
                document.body.appendChild(link);
                link.click();
              
                setTimeout(function () {
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(fileURL);
                }, 100);
            }

            resolve(true);
        }).catch(function (error) {
            resolve(false);
        });
    }); 
}

export function getAppEnvCookieTest() {    
    return (process.env.REACT_APP_PUBLIC_URL === 'https://www.partssource.com' || process.env.REACT_APP_PUBLIC_URL === 'https://rc.partssource.com') ? 
        (`<script type="text/javascript" src="https://cdn.cookielaw.org/consent/4b123739-e109-444b-92fd-98daa3d29630/OtAutoBlock.js"></script>
        <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="4b123739-e109-444b-92fd-98daa3d29630"></script>`) :
        (`<script type="text/javascript" src="https://cdn.cookielaw.org/consent/4b123739-e109-444b-92fd-98daa3d29630-test/OtAutoBlock.js"></script>
        <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="4b123739-e109-444b-92fd-98daa3d29630-test"></script>`);
}

export function getAdobeAnalyticsURL() {
    switch (process.env.REACT_APP_PUBLIC_URL) {
        case 'https://www.partssource.com':
            return 'https://assets.adobedtm.com/ff25ec85e336/b28e6ae69f56/launch-a4bb8a7ebe22.min.js';
        case 'https://rc.partssource.com':
        case 'https://demo.partssource.com':
        case 'https://trng.partssource.com':
            return 'https://assets.adobedtm.com/ff25ec85e336/b28e6ae69f56/launch-377808e86d93-staging.min.js';
        default:
            return 'https://assets.adobedtm.com/ff25ec85e336/b28e6ae69f56/launch-dfc62a82c528-development.min.js';
    }
}

// Get inactive group ids
const otGetInactiveId = (otDomainGrps, otActiveGrp) => {
    // After action OnetrustActiveGroups
    otActiveGrp = otActiveGrp.split(',');
    otActiveGrp = otActiveGrp.filter(Boolean);

    let result = [];
    otDomainGrps.map(o => {
        if (otActiveGrp.indexOf(o.CustomGroupId) <= -1) {
            result.push(o.CustomGroupId);
        }
    });
    return result;
}

export function getInactiveCookieGroups() {
    if (window.Optanon) {
        let otDomainGrps = JSON.parse(JSON.stringify(window.Optanon.GetDomainData().Groups));
        return (otGetInactiveId(otDomainGrps, window.OnetrustActiveGroups));
    } else {
        return [];
    }
}

export function getPersonalizationCookiesEnabled() {
    let inactives = getInactiveCookieGroups();
    let index = inactives.indexOf('C0003');
    return (index === -1);
}

export function setSigSessionId() {
    try {
        let scriptTag = document.getElementById('sig-api');
        if (scriptTag) document.body.removeChild(scriptTag);
    } catch {
        console.timeLog('Failed to remove signifyd script');
    }

    try {
        const sigOrderSessionId = uuidv4();
        let scriptTag = document.createElement('script');
        scriptTag.setAttribute('async', true);
        scriptTag.setAttribute('data-order-session-id', sigOrderSessionId);
        scriptTag.setAttribute('id', 'sig-api');
        scriptTag.setAttribute('src', 'https://cdn-scripts.signifyd.com/api/script-tag.js');
        document.body.appendChild(scriptTag);
        return sigOrderSessionId
    } catch {
        console.log('Failed to load signifyd script');
        return null;
    }
}

export async function sha256(data) {
    try {
        if (typeof(data) === 'undefined') return '';
        return await crypto.subtle.digest('SHA-256', new TextEncoder().encode(data)).then(buffer => {
            return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
        });
    } catch (e) {
        return '';
    }
}

export const isValidEmail = (email) => (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email));

export const isValidNumber = (input) => {
    let inputString = String(input);
    return !isNaN(Number(inputString)) && inputString.trim() !== '';
}

export const isValidPhoneNumber = (phone) => {
    return phone && phone.length === 10 && !isNaN(phone);
}

export const isValidLink = (link) => {
    if (!link || link === '') return;

    const pattern = 
        /^((http|https):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
    
    if (pattern.test(link)) {
        return true;
    } else {
        return false;
    }
}

export const downloadCreditMemos = async (lineItemId) => {
    const pdfUrl = `/CustomerCommunicationsService/api/v1/report/credit-memo/${parseInt(lineItemId)}`;
    const response = await axios.get(pdfUrl);
    const files = response.data;
    files.forEach(file => {
        const blob = new Blob([Uint8Array.from(atob(file.fileBase64), c => c.charCodeAt(0))], { type: file.contentType });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.type = 'hidden';
        link.download = file.fileDownloadName;
        document.body.appendChild(link);
        link.click();

        setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }, 100);
    });
}

export const formatColumn = (column, row) => {
    if (column.isDate && row[column.fieldName])
        return moment(row[column.fieldName]).format('MM/DD/YYYY');
    else if (column.isMoney) 
            return formatMoney(row[column.fieldName]);
        else if (column.isBool) {
            if (row[column.fieldName])
                return 'true';
            else
                return 'false';
        } else
            return row[column.fieldName];
}
